import {
    AmpSettingsMap,
    Category,
    ConnectorDto,
    ConnectorStatus,
    MfaStatus,
    Page,
    ProviderDto,
    UserCoverageSummary,
    _MfaRating,
} from '@ampsec/platform-client';
import { HiMiniQuestionMarkCircle } from '@react-icons/all-files/hi2/HiMiniQuestionMarkCircle';
import { FaLinux } from '@react-icons/all-files/fa/FaLinux';
import { FaApple } from '@react-icons/all-files/fa/FaApple';
import { FaWindows } from '@react-icons/all-files/fa/FaWindows';
import { MdOutlineError } from '@react-icons/all-files/md/MdOutlineError';
import { ComponentProps, useContext } from 'react';
import { Table } from 'Components/organisms';
import { TableColumn } from 'react-data-table-component';
import { Typography } from 'Components/atoms';
import { useSelector } from 'react-redux';
import { RootState, store } from 'Rdx/store';
import { ContentfulContext } from 'Rdx/contexts/contentfulContext';
import _ from 'lodash';
import IconWithStatus from 'Components/molecules/IconWithStatus/IconWithStatus';
import { Avatar } from 'Components/atoms';
import {
    COLLABORATION,
    DEPARTMENT,
    ENDPOINTS,
    ENDPOINT_MANAGEMENT,
    ENDPOINT_SECURITY,
    IDENTITY_AND_ACCESS,
    NAME,
    NUMBER_OF_ENDPOINTS,
    SECURITY_TRAINING,
    VULNERABILITY_MANAGEMENT,
} from 'Core-utils/constants/constants';
import { OsType } from 'Core-utils/types';
import React from 'react';
import { ToolingCoverageTooltip } from 'Components/molecules/ToolingCoverageTooltip';
import { ToolingCoverageTooltipProps } from 'Components/molecules/ToolingCoverageTooltip/ToolingCoverageTooltip';
import Laptop from 'Assets/icons/laptop.svg';
import Mobile from 'Assets/icons/mobile.svg';
import { CustomTooltip } from 'Components/atoms';
import { USERS_DASHBOARD_ROUTE } from 'Core-utils/routes';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { DateTimeFormatter, getAssetDomains, getMFAStatusProps, getMFATooltipProps } from 'Core-utils/helpers/helpers';
import { useI18n } from 'Hooks/useI18n';
import { ReactSVG } from 'react-svg';
import { getConnectorSettings } from 'Apis/library';
import { useApiObjectData } from 'Hooks/useApiObjectData';
import { useConnectorData } from 'Hooks/useConnectorsData';
import '../styles.scss';

const ALLOWED_COLLABORATION_PROVIDERS = ['slack'];
const COLUMN_WIDTH = '11.5rem';

type ConnectorsType = {
    count: number;
    type: string;
    id: string;
    createdAt: string;
    updatedAt: string;
    deletedAt: string | null;
    displayValue: string;
    status: ConnectorStatus;
    pid?: string | null | undefined;
    provider: ProviderDto;
};
type AssetProviderType = {
    id: string;
    count: number;
    type: string;
    name: string;
    domains: string[];
    logo: string;
    status: ConnectorStatus;
};

type DomainColumn = {
    columnIcon: string;
    columnTitle?: string;
    columnWidth?: string;
};

type DomainToColumnMap = {
    [columnName: string]: DomainColumn;
};

type AssetsType = UserCoverageSummary['assets'][number];

const userDomainToTypeMap: DomainToColumnMap = {
    [IDENTITY_AND_ACCESS]: { columnIcon: 'user', columnWidth: '10rem' },
    [COLLABORATION]: { columnIcon: 'user', columnWidth: '10rem' },
    [SECURITY_TRAINING]: { columnIcon: 'user', columnWidth: '13rem' },
};
const assetDomainToTypeMap: DomainToColumnMap = {
    [ENDPOINT_MANAGEMENT]: { columnIcon: 'asset', columnWidth: '13rem' },
    [ENDPOINT_SECURITY]: { columnIcon: 'asset', columnWidth: '13rem' },
    [VULNERABILITY_MANAGEMENT]: { columnIcon: 'asset', columnTitle: 'Vuln Management', columnWidth: '13rem' },
};

const getOsIcon = (osType: OsType) => {
    const lowerCaseOsType = osType.toLowerCase();
    if (/windows/.test(lowerCaseOsType)) {
        return <FaWindows size={20} className="text-structural-stroke-100" />;
    } else if (/mac/.test(lowerCaseOsType)) {
        return <FaApple size={20} className="text-structural-stroke-100" />;
    } else if (/linux/.test(lowerCaseOsType)) {
        return <FaLinux size={20} className="text-structural-stroke-100" />;
    } else {
        return <HiMiniQuestionMarkCircle size={20} className="text-structural-stroke-100" />;
    }
};

const columns: (
    installedConnectorMap: { [cid: string]: ConnectorDto & { provider: ProviderDto } },
    providerNameIntegrationMap: { [providerName: string]: any },
) => (TableColumn<UserCoverageSummary> | null)[] = (installedConnectorMap, providerNameIntegrationMap) => {
    // const activeConnectors = Object.fromEntries(
    //     Object.entries(installedConnectorMap).filter(([_key, value]) => value.status === 'ACTIVE'),
    // );

    const tid = store.getState().auth?.user?.tid;
    const allConnectors = installedConnectorMap;
    const addedColumns = new Set<string>();
    const { data: tenantSettings, isLoading } = useApiObjectData<AmpSettingsMap, AmpSettingsMap>(
        'CONNECTOR_SETTINGS',
        () => getConnectorSettings(tid ?? ''),
        (a) => a,
    );

    const getConnectorData = (connectors: ConnectorsType[], columnName: string) => {
        return connectors
            .map((connector) => {
                const contentfulData = providerNameIntegrationMap?.[connector.provider?.displayValue];
                const assetDomains = getAssetDomains(connector.id, connector.provider?.displayValue, tenantSettings);
                const domains =
                    !isLoading && _.isEmpty(assetDomains)
                        ? contentfulData?.domains.map((domain: any) => domain.fields.name)
                        : assetDomains;

                return {
                    count: connector.count,
                    type: connector.type,
                    name: contentfulData?.name,
                    domains: domains,
                    logo: contentfulData?.logo?.fields?.file?.url,
                    status: connector.status,
                    id: connector.id,
                };
            })
            .filter((connector) => connector.domains?.includes(columnName));
    };

    const getAssetProviderContentfulData = (
        row: UserCoverageSummary,
        asset: AssetsType,
        columnName: string,
        columnType: string,
    ) => {
        const assetConnectors = asset
            ? Object.keys(asset?.assetAccounts2).map((cid) => ({
                  ...allConnectors[cid],
                  count: asset.assetAccounts2[cid].length,
                  type: 'asset',
              }))
            : [];
        const userConnectors = Object.keys(row.userAccounts2).map((cid) => ({
            ...allConnectors[cid],
            count: row.userAccounts2[cid].length,
            type: 'user',
        }));

        return columnType === 'asset'
            ? getConnectorData(assetConnectors, columnName)
            : getConnectorData(userConnectors, columnName);
    };
    const generateHeaderIcon = (
        accounts: Record<
            string,
            {
                active: boolean;
            }[]
        >,
        assetProvider: AssetProviderType,
        index: number,
    ) => {
        const account = accounts?.[assetProvider.id]?.[index];
        return <IconWithStatus imageSrc={assetProvider.logo} status={account?.active ? 'active' : 'warn'} />;
    };

    const generateTooltipContent = (
        accounts: Record<
            string,
            {
                active: boolean;
                lastActivity?: string;
            }[]
        >,
        asset: AssetsType,
        assetProvider: AssetProviderType,
        index: number,
    ) => {
        return [
            {
                key: 'Last Check in',
                value: DateTimeFormatter(accounts?.[assetProvider.id]?.[index]?.lastActivity ?? ''),
            },
            {
                key: 'Status',
                value: accounts?.[assetProvider.id]?.[index]?.active ? 'ACTIVE' : 'DISABLED',
            },
        ];
    };
    const getCustomTooltipProps = (
        userAccounts2: Record<
            string,
            {
                active: boolean;
            }[]
        >,
        asset: AssetsType,
        assetProvider: AssetProviderType,
        index: number,
        columnName: string,
        mfaStatus?: MfaStatus,
        mfaFactors?: UserCoverageSummary['mfaFactors'],
    ): ToolingCoverageTooltipProps => {
        const customTooltipMap: Record<string, ToolingCoverageTooltipProps> = {
            [IDENTITY_AND_ACCESS]: {
                headerIcon: generateHeaderIcon(userAccounts2, assetProvider, index),
                headerLabel: assetProvider.name,
                tooltipContent: [
                    ...generateTooltipContent(userAccounts2, asset, assetProvider, index),
                    {
                        key: 'Secure',
                        value: mfaStatus?.split('_').join(' ') ?? 'DISABLED',
                    },
                ],
                customContentHeader: 'MFA Factors',
                customContent: getMFATooltipProps(mfaFactors),
            },
            [SECURITY_TRAINING]: {
                headerIcon: generateHeaderIcon(userAccounts2, assetProvider, index),
                headerLabel: assetProvider.name,
                tooltipContent: generateTooltipContent(userAccounts2, asset, assetProvider, index),
            },
            [ENDPOINT_MANAGEMENT]: {
                headerIcon: generateHeaderIcon(asset?.assetAccounts2, assetProvider, index),
                headerLabel: assetProvider.name,
                tooltipContent: generateTooltipContent(asset?.assetAccounts2, asset, assetProvider, index),
            },
            [VULNERABILITY_MANAGEMENT]: {
                headerIcon: generateHeaderIcon(asset?.assetAccounts2, assetProvider, index),
                headerLabel: assetProvider.name,
                tooltipContent: generateTooltipContent(asset?.assetAccounts2, asset, assetProvider, index),
            },
            [ENDPOINT_SECURITY]: {
                icon: <img className="text-structural-stroke-100" src={Laptop} />,
                headerIcon: generateHeaderIcon(asset?.assetAccounts2, assetProvider, index),
                headerLabel: assetProvider.name,
                tooltipContent: generateTooltipContent(asset?.assetAccounts2, asset, assetProvider, index),
            },
            [COLLABORATION]: {
                headerIcon: generateHeaderIcon(userAccounts2, assetProvider, index),
                headerLabel: assetProvider.name,
                tooltipContent: [
                    ...generateTooltipContent(userAccounts2, asset, assetProvider, index),
                    {
                        key: 'Secure',
                        value: mfaStatus?.split('_').join(' ') ?? 'DISABLED',
                    },
                ],
                customContentHeader: 'MFA Factors',
                customContent: getMFATooltipProps(mfaFactors),
            },
        };
        const customTooltipProps = customTooltipMap[columnName] || {};
        return customTooltipProps;
    };

    const renderAssetIcons = (
        userAccounts2: Record<
            string,
            {
                active: boolean;
            }[]
        >,
        asset: AssetsType,
        assetProvider: AssetProviderType,
        columnType: string,
        columnName: string,
        mfaStatus?: MfaStatus,
        mfaFactors?: UserCoverageSummary['mfaFactors'],
    ) => {
        return (
            <>
                {new Array(assetProvider.count).fill(0)?.map((_, index) => {
                    const customTooltipProps = getCustomTooltipProps(
                        userAccounts2,
                        asset,
                        assetProvider,
                        index,
                        columnName,
                        mfaStatus,
                        mfaFactors,
                    );
                    const status =
                        columnType === 'asset'
                            ? asset?.assetAccounts2[assetProvider.id]?.[index]?.active
                            : userAccounts2[assetProvider.id]?.[index]?.active;

                    type SecondaryStatus = Pick<
                        ComponentProps<typeof IconWithStatus>,
                        'secondaryStatus'
                    >['secondaryStatus'];
                    const secondaryStatus: SecondaryStatus = mfaStatus
                        ? (getMFAStatusProps(mfaStatus).variantValue as SecondaryStatus)
                        : undefined;
                    // const customContent = mfaFactors && mfaFactors.length ? getMFATooltipProps(mfaFactors) : undefined;
                    return (
                        <div
                            key={`${assetProvider.domains.join('-')}-${assetProvider.name}-${index}`}
                            className="users-insights-modal__cont"
                            data-testid={`${assetProvider.id}`}
                        >
                            <ToolingCoverageTooltip {...customTooltipProps}>
                                <IconWithStatus
                                    imageSrc={assetProvider.logo}
                                    status={status ? 'active' : 'warn'}
                                    iconClassName="users-insights-modal__provider_logo"
                                    secondaryStatus={secondaryStatus}
                                />
                            </ToolingCoverageTooltip>
                        </div>
                    );
                })}
            </>
        );
    };
    const normalize = (value: Category): string => _.startCase((value ?? '').toLowerCase().replace(/_/g, ' '));

    const renderConnectorContent = (
        row: UserCoverageSummary,
        asset: AssetsType,
        type: DomainColumn,
        columnName: string,
    ) => {
        let assetProviderContentfuldata = getAssetProviderContentfulData(row, asset, columnName, type.columnIcon);
        if (columnName === COLLABORATION) {
            assetProviderContentfuldata = assetProviderContentfuldata.filter((data) =>
                ALLOWED_COLLABORATION_PROVIDERS.some((provider) => {
                    const regExp = new RegExp(provider, 'i');
                    return regExp.test(data.name);
                }),
            );
        }
        const accumulatedCount = assetProviderContentfuldata.reduce((accumulator, currentObject) => {
            return accumulator + currentObject.count;
        }, 0);

        const { domainsWithNoConnectors, extractedData } = useConnectorData();
        const domainWithDisabledConnectors = extractedData?.filter((data) => data.status === ConnectorStatus.DISABLED);
        const isDisabledConnector = domainWithDisabledConnectors.some((data) =>
            data.category.map((category) => normalize(category)).includes(columnName as Category),
        );
        const errorIcon =
            domainsWithNoConnectors.includes(columnName) || isDisabledConnector ? (
                <MdOutlineError className="text-text-low-emphasis" size={30} style={{ opacity: 0.3 }} />
            ) : (
                <MdOutlineError className="text-secondary-accent-red-2" size={30} />
            );
        if (assetProviderContentfuldata.length > 0) {
            if (accumulatedCount > 0) {
                return (
                    <div className="d-flex gap-2 align-items-center users-insights-modal__icon_cont">
                        {assetProviderContentfuldata?.map((a) => {
                            const { mfaStatus, mfaFactors } =
                                columnName === COLLABORATION || columnName === IDENTITY_AND_ACCESS
                                    ? {
                                          mfaStatus: row.mfaReports?.[a.id]?.mfaStatus ?? MfaStatus.DISABLED,
                                          mfaFactors: row.mfaReports?.[a.id]?.mfaFactors ?? undefined,
                                      }
                                    : { mfaStatus: undefined, mfaFactors: undefined };

                            return (
                                <React.Fragment key={`${a.domains.join('-')}-${a.id}`}>
                                    {renderAssetIcons(
                                        row.userAccounts2,
                                        asset,
                                        a as AssetProviderType,
                                        type.columnIcon,
                                        columnName,
                                        mfaStatus,
                                        mfaFactors,
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </div>
                );
            } else {
                if (row.name?.toLowerCase() !== 'unassigned') {
                    return <CustomTooltip label="Account/tool missing">{errorIcon}</CustomTooltip>;
                } else {
                    if (type.columnIcon === 'asset') {
                        return <CustomTooltip label="Account/tool missing">{errorIcon}</CustomTooltip>;
                    }
                }
            }
        } else {
            return <CustomTooltip label="Account/tool missing">{errorIcon}</CustomTooltip>;
        }
    };

    const getUserCellContent = (
        row: UserCoverageSummary,
        asset: AssetsType,
        type: DomainColumn,
        columnName: string,
    ) => {
        switch (columnName) {
            case IDENTITY_AND_ACCESS:
                return (
                    <div className="d-flex align-items-center justify-content-center gap-2">
                        {renderConnectorContent(row, row.assets[0], type, columnName)}
                    </div>
                );
            case COLLABORATION:
                if (row.name?.toLowerCase() === 'unassigned') {
                    return <></>;
                }
                return (
                    <div className="d-flex gap-2 justify-content-between">
                        {renderConnectorContent(row, asset, type, columnName)}
                    </div>
                );
            default:
                return <React.Fragment>{renderConnectorContent(row, asset, type, columnName)}</React.Fragment>;
        }
    };

    const getCellContent = (row: UserCoverageSummary, columnName: string, type: DomainColumn, isAssetCell: boolean) => {
        const className = classNames('d-flex flex-column gap-4', {
            'w-100': columnName === IDENTITY_AND_ACCESS,
            'users-insights-modal__opacity-transparent': !row.assets[0]?.isAssetActive && isAssetCell,
        });
        return (
            <div className={className}>
                {isAssetCell
                    ? row.assets.map((asset) => (
                          <div key={asset.id} className="users-insights-modal__cont">
                              {renderConnectorContent(row, asset, type, columnName)}
                          </div>
                      ))
                    : getUserCellContent(row, row.assets[0], type, columnName)}
            </div>
        );
    };

    const genColumn = (columnName: string, type: DomainColumn) => {
        addedColumns.add(columnName);
        return {
            id: columnName,
            name: (
                <div className="d-flex gap-2 align-items-center">
                    <Typography style={{ wordBreak: 'keep-all' }} variant="body2">{`${
                        type?.columnTitle ?? columnName
                    } `}</Typography>
                </div>
            ),
            width: type.columnWidth ?? COLUMN_WIDTH,
            cell: (row: UserCoverageSummary) => getCellContent(row, columnName, type, type.columnIcon === 'asset'),
            center: columnName === IDENTITY_AND_ACCESS ? false : true,
        };
    };
    const userColumns = Object.keys(userDomainToTypeMap)
        .filter((domain) => !addedColumns.has(domain))
        .map((domain) => genColumn(domain, userDomainToTypeMap[domain]));

    const assetColumns = Object.keys(assetDomainToTypeMap)
        .filter((domain) => !addedColumns.has(domain))
        .map((domain) => genColumn(domain, assetDomainToTypeMap[domain]));

    const { t } = useI18n();

    return [
        {
            allowOverflow: false,
            id: 'name',
            name: (
                <div>
                    <Typography variant="body2">{NAME}</Typography>
                </div>
            ),
            width: '15rem',
            cell: (row) =>
                row.active ? (
                    <CustomTooltip label={row.email ?? ''}>
                        <Link
                            className={`d-flex gap-2 text-uppercase w-100 text-decoration-none data-grid__link`}
                            to={`${USERS_DASHBOARD_ROUTE}/${row.id}`}
                        >
                            <Avatar
                                variant={'avatar'}
                                name={row?.name?.charAt(0) || ''}
                                height="25px"
                                width="25px"
                                // status={row.active ? 'active' : 'warn'}
                            />
                            <Typography
                                style={{
                                    height: '50%',
                                    margin: 'auto',
                                    width: '100%',
                                    textOverflow: 'ellipsis',
                                    textTransform: 'capitalize',
                                }}
                                variant="body2"
                            >
                                {row.name}
                            </Typography>
                        </Link>
                    </CustomTooltip>
                ) : (
                    <ToolingCoverageTooltip
                        tooltipContent={[
                            {
                                key: 'Inactive',
                                value: row.assets[0]?.lastCheckinTs ?? '',
                            },
                        ]}
                    >
                        <div className=" d-flex gap-2 text-uppercase w-100">
                            <Avatar
                                variant={'avatar'}
                                name={row?.name?.charAt(0) || ''}
                                height="25px"
                                width="25px"
                                // status={row.active ? 'active' : 'warn'}
                            />
                            <Typography
                                style={{
                                    height: '50%',
                                    margin: 'auto',
                                    width: '100%',
                                    textOverflow: 'ellipsis',
                                    textTransform: 'capitalize',
                                }}
                                variant="body2"
                            >
                                {row.name}
                            </Typography>
                        </div>
                    </ToolingCoverageTooltip>
                ),
        },
        {
            allowOverflow: false,
            id: 'department',
            name: (
                <div>
                    <Typography variant="body2">{DEPARTMENT}</Typography>
                </div>
            ),
            width: '14rem',
            cell: (row) => {
                return (
                    <div className="text-uppercase w-100">
                        <CustomTooltip label={row.department ?? ''}>
                            <Typography
                                style={{
                                    height: '50%',
                                    margin: 'auto',
                                    width: '12rem',
                                    textTransform: 'capitalize',
                                }}
                                className="text-truncate"
                                variant="body2"
                            >
                                {row.department}
                            </Typography>
                        </CustomTooltip>
                    </div>
                );
            },
        },
        {
            allowOverflow: false,
            id: 'numberOfEndpoints',
            name: (
                <div>
                    <Typography variant="body2">{NUMBER_OF_ENDPOINTS}</Typography>
                </div>
            ),
            width: '13rem',
            cell: (row) => {
                return (
                    <div className="text-uppercase w-100">
                        <Typography
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                height: '50%',
                                margin: 'auto',
                                width: '100%',
                                textOverflow: 'ellipsis',
                            }}
                            variant="body2"
                        >
                            {row.assets.length}
                        </Typography>
                    </div>
                );
            },
        },
        ...userColumns,
        {
            allowOverflow: false,
            id: 'endpoints',
            name: (
                <div>
                    <Typography variant="body2">{ENDPOINTS}</Typography>
                </div>
            ),
            width: '13rem',
            cell: (row) => {
                const mobileCategoriesArray: string[] = t('__mobile-categories')
                    .split(',')
                    .map((category) => category.toLowerCase());
                const deviceClassName = classNames('d-flex flex-column gap-4', {
                    'users-insights-modal__opacity-transparent': !row.assets[0]?.isAssetActive,
                });
                return (
                    <div className={deviceClassName}>
                        {row.assets.map((asset) => {
                            const assetString = `${asset?.os || ''}${asset?.model || ''}`.toLowerCase();

                            const imageSrc = mobileCategoriesArray.some((pattern) => {
                                const regex = new RegExp(pattern, 'i');
                                return regex.test(assetString);
                            })
                                ? Mobile
                                : Laptop;
                            return (
                                <ToolingCoverageTooltip
                                    key={`asset-${asset.id}`}
                                    icon={<ReactSVG className="users-insights-modal__svg-icon" src={imageSrc} />}
                                    headerLabel={asset?.displayValue}
                                    headerIcon={getOsIcon((asset?.os as OsType) ?? '')}
                                    tooltipContent={[
                                        {
                                            key: 'Last Check in',
                                            value: asset?.lastCheckinTs ?? '',
                                        },
                                        {
                                            key: 'Host Name',
                                            value: asset?.hostname ?? '',
                                        },

                                        {
                                            key: 'Serial Number',
                                            value: asset?.sn ?? '',
                                        },
                                        {
                                            key: 'OS Version',
                                            value: asset?.os ?? '',
                                        },
                                        {
                                            key: 'Model',
                                            value: asset?.model ?? '',
                                        },
                                    ]}
                                >
                                    <div className="d-flex gap-3 text-uppercase w-100 users-insights-modal__icon_cont align-items-center">
                                        {asset?.displayValue && <img src={imageSrc} width={25} height={25} />}
                                        <Typography
                                            variant={'subtitle3'}
                                            style={{
                                                maxWidth: '10rem',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {asset?.displayValue}
                                        </Typography>
                                    </div>
                                </ToolingCoverageTooltip>
                            );
                        })}
                    </div>
                );
            },
        },
        ...assetColumns,
    ];
};

interface UsersInsightsModalBodyProps {
    defaultFilter?: {
        [x: string]: string[];
    };
    tableBodyHeight?: string;
}

const UsersInsightsModalBody: React.FC<UsersInsightsModalBodyProps> = ({ defaultFilter, tableBodyHeight }) => {
    const { contentfulData } = useContext(ContentfulContext);
    const providerNameIntegrationMap = contentfulData?.providerNameIntegrationMap;
    const installedConnectorMap = useSelector((state: RootState) => state.installedConnectorMap);

    const ROWS_PER_PAGE_OPTIONS = [50, 150, 250];
    return (
        <Table
            defaultSortId="name"
            showSubHeader={true}
            subHeaderVariant="with-button"
            cacheKey="usersInsights"
            columns={columns(installedConnectorMap, providerNameIntegrationMap) as TableColumn<UserCoverageSummary>[]}
            pagination={true}
            transformer={(dataItem: Page<UserCoverageSummary>) => dataItem}
            rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
            tableBodyHeight={tableBodyHeight}
            filter={defaultFilter}
        />
    );
};

export default UsersInsightsModalBody;
