import { IoCheckmarkCircle } from '@react-icons/all-files/io5/IoCheckmarkCircle';
import { IoIosWarning } from '@react-icons/all-files/io/IoIosWarning';
import { IoLockClosed } from '@react-icons/all-files/io5/IoLockClosed';
import './styles.scss';
import classNames from 'classnames';

type Status = 'warn' | 'active' | 'paused' | 'error' | 'ghost';

type IconWithStatusProps = {
    imageSrc?: string;
    iconClassName?: string;
    status: Extract<Status, 'warn' | 'active'>;
    secondaryStatus?: Status;
};

const StatusIcon = ({ status }: IconWithStatusProps) => {
    switch (status) {
        case 'warn':
            return <IoIosWarning className="icon-status__logo text-secondary-accent-yellow-1" />;
        case 'active':
            return <IoCheckmarkCircle className="icon-status__logo text-vizualization-green-300" />;
    }
};

const SecondaryStatusIcon = ({ status }: { status?: Status }) => {
    const logoClassNames = classNames('icon-status__secondary-logo', {
        'text-secondary-accent-yellow-1': status === 'warn',
        'text-vizualization-green-300': status === 'active',
        'text-secondary-accent-red-1': status === 'error',
        'text-text-low-emphasis': status === 'ghost',
        'text-secondary-accent-orange-3': status === 'paused',
    });
    return <IoLockClosed className={logoClassNames} />;
};

const IconWithStatus = ({ imageSrc, iconClassName, status, secondaryStatus }: IconWithStatusProps) => {
    return (
        <div className={'icon-status'} data-testid="icon-status-container">
            <img className={iconClassName} src={imageSrc} width={25} height={25} />
            <StatusIcon status={status} />
            {secondaryStatus && <SecondaryStatusIcon status={secondaryStatus} />}
        </div>
    );
};

export default IconWithStatus;
